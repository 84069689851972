<template>
  <div class="pt-8 mt-0">
    <v-card elevation="0">
      <v-card-title class="secondary white--text">
        Administración de perfiles
      </v-card-title>
      <v-card-actions
        class="pt-4 mt-4 pl-4 ml-4"
        v-if="haveRole('ROLE_PROVEEDOR')"
      >
        <v-btn @click="modalAgregar = true" class="secondary">
          Agregar perfil
        </v-btn>
      </v-card-actions>
      <v-card-text class="pt-4 mt-4">
        <v-data-table
          :items="empleados"
          :headers="getHeaders()"
          :server-items-length="+totalItems"
          @pagination="paginar"
          :item-class="getRowClass"
        >
          <template #[`item.acciones`]="{ item }">
            <app-menu-acciones
              v-if="haveRole('ROLE_PROVEEDOR')"
              :menu="getAcciones(item)"
              titulo="Acciones"
              :elemento="item"
            ></app-menu-acciones>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <modalAgregarComponent :modalAgregar="modalAgregar" @cerrar="cerrarModal" />
    <modalEditarCaducidad
      :modalEditar="modalEditar"
      @cerrar="cerrarModalEditar"
      :id="userSelectedToAction?.Usuario?.id || 0"
    />
    <ConfirmationDialogComponent
      :show="showModalObservacion"
      btnConfirmar="Remover observación"
      :title="`¿Desea remover la inactividad?`"
      message="El empleado deberá ingresar al sistema esta semana, de lo contrario será marcado como inactivo nuevamente."
      @close="showModalObservacion = false"
      @confirm="removerObservacion"
    />
    <ConfirmationDialogComponent
      :show="showModalCambiarEstado"
      btnConfirmar="Cambiar"
      :title="`¿Desea cambiar el estado del perfil?`"
      @close="showModalCambiarEstado = false"
      @confirm="cambiarEstado"
    />
    <ConfirmationDialogComponent
      :show="showModalDelete"
      btnConfirmar="Eliminar"
      :title="`¿Desea eliminar el perfil?`"
      @close="showModalDelete = false"
      @confirm="eliminar"
    />
    <ConfirmationDialogComponent
      :show="showRestablecer"
      btnConfirmar="Restablecer"
      :title="`¿Desea restablecer la contraseña?`"
      @close="showRestablecer = false"
      @confirm="restablecerContra"
    />
    <ConfirmationDialogComponent
      :show="showRestablecer2fa"
      btnConfirmar="Restablecer"
      :title="`¿Desea restablecer el 2fa a correo?`"
      @close="showRestablecer2fa = false"
      @confirm="restablecer2fa"
    />
  </div>
</template>
<script>
import moment from "moment";
import modalAgregarComponent from "./modalEmpleado.vue";
import modalEditarCaducidad from "./modalEditarCaducidad.vue";
import ConfirmationDialogComponent from "../../../components/ConfirmationDialogComponent.vue";
export default {
  name: "IndexEmpleadosProveedor",
  data: () => ({
    modalAgregar: false,
    showModalObservacion: false,
    showRestablecer: false,
    showModalCambiarEstado: false,
    showModalDelete: false,
    showRestablecer2fa: false,
    userSelectedToAction: null,
    modalEditar: null,
    mostrar: true,
    empleados: [],
    totalItems: 0,
    options: {
      page: 1,
      per_page: 10,
    },
  }),
  components: {
    modalAgregarComponent,
    ConfirmationDialogComponent,
    modalEditarCaducidad,
  },
  computed: {
    id_proveedor() {
      return this.$route.params.id_proveedor || false;
    },
  },
  methods: {
    async obtenerEmpleados() {
      const {
        data,
        headers: { total_rows },
      } = await this.services.EmpleadosProveedor.obtenerEmpleados(
        this.options,
        this.id_proveedor
      );
      this.empleados = data;
      this.totalItems = total_rows;
    },
    async cerrarModal() {
      this.modalAgregar = false;
      await this.obtenerEmpleados();
    },
    getRowClass(item) {
      if (moment().diff(moment(item.Usuario.fecha_caducidad), "days") > 0) {
        return "highlight-row-vencido";
      }
      if (!item.activo) {
        return "highlight-row-inactivo";
      }
      return "highlight-row-activo";
    },
    async paginar({ page, itemsPerPage }) {
      this.options.page = page;
      this.options.per_page = itemsPerPage;
      await this.obtenerEmpleados();
    },
    getAcciones(item) {
      return [
        {
          nombre: "Eliminar",
          icono: "mdi-delete",
          callback2: () => (
            (this.userSelectedToAction = item), (this.showModalDelete = true)
          ),
          disabled: !!item.deleted_at,
        },
        {
          nombre: "Editar fecha caducidad",
          icono: "mdi-calendar",
          callback2: () => (
            (this.userSelectedToAction = item), (this.modalEditar = true)
          ),
          disabled: !!item.deleted_at,
        },
        {
          nombre: item.activo ? "Desactivar" : "Activar",
          icono: "mdi-account-cancel-outline",
          callback2: () => (
            (this.userSelectedToAction = item),
            (this.showModalCambiarEstado = true)
          ),
          disabled: false,
        },
        {
          nombre: "Remover inactividad",
          icono: "mdi-check",
          callback2: () => (
            (this.userSelectedToAction = item),
            (this.showModalObservacion = true)
          ),
          disabled: !item.Usuario.observado,
        },
        {
          nombre: "Restablecer contraseña",
          icono: "mdi-lock",
          callback2: () => (
            (this.userSelectedToAction = item), (this.showRestablecer = true)
          ),
          disabled: false,
        },
        {
          nombre: "Restablecer 2fa a correo",
          icono: "mdi-lock",
          callback2: () => (
            (this.userSelectedToAction = item), (this.showRestablecer2fa = true)
          ),
          disabled: false,
        },
      ];
    },
    getHeaders() {
      const headers = [
        {
          text: "Nombre completo",
          align: "left",
          value: "Persona.nombre",
        },
        {
          text: "Correo asociado",
          align: "left",
          value: "Usuario.email",
        },
        {
          text: "Cargo funcional",
          align: "left",
          value: "Usuario.cargo_funcional",
        },
        {
          text: "Válido hasta",
          align: "left",
          value: "Usuario.fecha_caducidad",
        },
      ];
      if (this.haveRole("ROLE_PROVEEDOR")) {
        headers.push({
          text: "Acciones",
          align: "left",
          value: "acciones",
        });
      }
      return headers;
    },
    async removerObservacion() {
      await this.services.EmpleadosProveedor.removerInactividad(
        this.userSelectedToAction.id_usuario
      );
      this.showModalObservacion = false;
      await this.obtenerEmpleados();
    },
    async cambiarEstado() {
      await this.services.EmpleadosProveedor.cambiarEstado(
        this.userSelectedToAction.id_usuario
      );
      this.showModalCambiarEstado = false;
      await this.obtenerEmpleados();
    },
    async eliminar() {
      await this.services.EmpleadosProveedor.eliminarEmpleado(
        this.userSelectedToAction.id_usuario
      );
      this.showModalDelete = false;
      await this.obtenerEmpleados();
    },
    async cerrarModalEditar() {
      this.modalEditar = false;
      await this.obtenerEmpleados();
    },
    async restablecerContra() {
      await this.services.EmpleadosProveedor.restablecer(
        this.userSelectedToAction.id_usuario
      );
      this.showRestablecer = false;
      await this.obtenerEmpleados();
    },
    async restablecer2fa() {
      await this.services.EmpleadosProveedor.restablecer2fa(
        this.userSelectedToAction.id_usuario
      );
      this.showRestablecer2fa = false;
      await this.obtenerEmpleados();
    },
  },
  async created() {
    await this.obtenerEmpleados();
  },
};
</script>
<style>
.highlight-row-inactivo {
  background-color: #eed1ae;
}
.highlight-row-vencido {
  background-color: #ca5f34;
}
.highlight-row-activo {
  background-color: #ffffff;
}
</style>
